<template>
  <div class="pt20">
    <div class="pt10 pb10 plr15 bgf">
      <input
        class="link-input"
        type="text"
        v-model="linkVal"
        placeholder="输入网页地址,如www.qq.com"
      />
    </div>
    <div class="pt8 pl15 pb25 fs12 cl99">网页将以链接气泡的形式发送给客户</div>
    <div class="btn-box flex plr30">
      <button class="handle-def-btn flex-1 mr15" @click="onSubmit(false)">
        取消
      </button>
      <button class="handle-btn flex-1" @click="onSubmit(true)">提交</button>
    </div>
  </div>
</template>

<script>
import { EVENTCONST } from '@/utils/constants'
import bus from '@/utils/bus'
// import { navigateBack } from '@/utils/router.js'
export default {
  data() {
    return {
      linkVal: ''
    }
  },
  methods: {
    // 提交返回上一页
    onSubmit(isSubmit) {
      if (isSubmit) {
        let url = ''
        // 判断链接是否有https
        if (this.linkVal.indexOf('http') == -1) {
          url = 'https://' + this.linkVal
        } else {
          url = this.linkVal
        }
        bus.emit(EVENTCONST.SENDTEXT, url)
      }

      setTimeout(() => {
        this.$router.back()
        // navigateBack()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.link-input {
  width: 100%;
  font-size: 16px;
  border: none;
}
</style>
